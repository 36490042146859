import React, { useState, useMemo, useCallback } from 'react';
import { useCSVReader } from 'react-papaparse';
import { InternalRefetchQueriesInclude } from '@apollo/client';
import { ButtonV2, Flex, TextV2, LinkV2, Divider, Box } from '@withjoy/joykit';
import { ResponsiveValue } from '@withjoy/joykit/styled-system';
import type { Property } from 'csstype';
import { ButtonVariant } from '@withjoy/joykit/components/ButtonV2/Button.types';
import { Import, Info } from '@withjoy/joykit/icons';
import { ResponsiveDialog } from '@apps/card/components/ResponsiveDialog';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useCreateContactsMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { isAValidContact, CONTACT_PROPERTIES_LABEL, mapContactsToMutationData, hasAllRequiredColumns, mapCSVContactToContact } from './CSVUpload.utils';
import { Table } from './CSVUpload.styles';
import { TelemetryProvider, useCSVUploadTelemetry } from './CSVUpload.telemetry';

const TEMPLATE_URL = 'https://docs.google.com/spreadsheets/d/1eGtdrsJ1iYlnvRYqd2mg8zXT1_wF4EDEs4D_VH1lkSg';
const HELP_DOC_URL = 'https://withjoy.com/help/en/articles/8309207-importing-and-exporting-your-guest-list';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  context: 'print' | 'contactCollector';
  contactCollectionId: string;
  refetchQueries?: InternalRefetchQueriesInclude;
}

const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, context, contactCollectionId, refetchQueries }) => {
  const { trackError, buttonInteracted } = useCSVUploadTelemetry();
  const [createContactsMutation, { loading: isCreateContactsMutationLoading, error: createContactsMutationError }] = useCreateContactsMutation({
    refetchQueries,
    awaitRefetchQueries: true
  });
  const [step, setStep] = useState<'first' | 'second' | 'third'>('first');
  const [hasParseError, setHasParseError] = useState<boolean>(false);
  const [hasRequiredColumnsError, setHasRequiredColumnsError] = useState<boolean>(false);
  const [validRows, setValidRows] = useState<Contact[]>([]);
  const [invalidRows, setInvalidRows] = useState<Contact[]>([]);
  const { eventInfo, eventHandle } = useEventInfo();
  const eventFirebaseId = eventInfo?.eventFirebaseId;
  const { CSVReader } = useCSVReader();
  const { t } = useTranslation('contactCollector');
  const translation = t('admin', 'csvUpload');
  const guestsListUrl = eventHandle ? `/${eventHandle}/edit/guests` : undefined;

  const validRowsLength = useMemo(() => validRows.length, [validRows]);
  const invalidRowsLength = useMemo(() => invalidRows.length, [invalidRows]);

  const handleOnImportClick = useCallback(async () => {
    try {
      if (!eventFirebaseId) {
        throw Error('No Firebase Event Id provided');
      }
      const mutationData = mapContactsToMutationData(validRows, contactCollectionId, eventFirebaseId);
      await createContactsMutation({ variables: { data: mutationData } });
      buttonInteracted(context, { name: 'contactsCreated', step, contacts: validRowsLength + invalidRowsLength, valid: validRowsLength, invalid: invalidRowsLength });
    } catch {
      trackError(context, 'Mutation', { name: 'createContactsMutationError', step });
    }
    setStep('third');
  }, [eventFirebaseId, validRows, contactCollectionId, createContactsMutation, buttonInteracted, context, step, validRowsLength, invalidRowsLength, trackError]);

  const handleOnCloseDialogClick = () => {
    buttonInteracted(context, { name: 'closeDialog', step });
    onClose();
  };

  const handleOnViewContactsClick = useCallback(() => {
    buttonInteracted(context, { name: 'viewContacts', step });
    onClose();
  }, [buttonInteracted, context, onClose, step]);

  const processResults = useCallback(
    (results: CSVResult) => {
      setHasParseError(false);
      setHasRequiredColumnsError(false);
      setValidRows([]);
      setInvalidRows([]);
      const validRows: Contact[] = [];
      const invalidRows: Contact[] = [];
      const data = results?.data;
      const hasParseError = !!results?.errors?.length;
      setHasParseError(hasParseError);
      if (hasParseError) {
        trackError(context, 'Other', { name: 'parseError', step });
      }
      // validate if some row does not match header columns quantity or there is some separator error - file validation
      if (!hasParseError && data?.length) {
        // validate if all the rows have the required columns - file validation
        if (hasAllRequiredColumns(data)) {
          data.forEach(csvContact => {
            const contact = mapCSVContactToContact(csvContact);
            // validate if the contact is a valid Contact - data validation
            if (isAValidContact(contact)) {
              validRows.push(contact);
            } else {
              invalidRows.push(contact);
            }
          });
          setValidRows(validRows);
          setInvalidRows(invalidRows);
          const validRowsLength = validRows.length;
          const invalidRowsLength = invalidRows.length;
          buttonInteracted(context, { name: 'fileUploaded', step, contacts: validRowsLength + invalidRowsLength, valid: validRowsLength, invalid: invalidRowsLength });
        } else {
          setHasRequiredColumnsError(true);
          trackError(context, 'Other', { name: 'requiredColumnsError', step });
        }
      }
      if (step !== 'second') {
        setStep('second');
      }
    },
    [buttonInteracted, context, step, trackError]
  );

  const renderCSVReader = useMemo(
    () => ({
      startIcon = false,
      buttonLabel,
      variant,
      marginBottom
    }: {
      startIcon?: boolean;
      buttonLabel: string;
      variant?: ButtonVariant;
      marginBottom?: ResponsiveValue<Property.MarginBottom<string | number>>;
    }) => (
      <CSVReader
        onUploadAccepted={(results: CSVResult) => {
          buttonInteracted(context, { name: buttonLabel, step });
          processResults(results);
        }}
        config={{ header: true }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {({ getRootProps }: any) => (
          <ButtonV2 startIcon={startIcon ? <Import /> : undefined} variant={variant} marginBottom={marginBottom} intent="neutral" shape="rounded" width="350px" {...getRootProps()}>
            {buttonLabel}
          </ButtonV2>
        )}
      </CSVReader>
    ),
    [CSVReader, buttonInteracted, context, processResults, step]
  );

  const FirstStepUI = useMemo(
    () => (
      <Flex flexDirection="column">
        <TextV2 typographyVariant="body2" color="mono14">
          {translation.please()}
          <LinkV2
            typographyVariant="body1"
            color="mono14"
            paddingX={1}
            href={TEMPLATE_URL}
            onClick={() => buttonInteracted(context, { name: 'downloadTemplate', step })}
            isExternal
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14' }}
          >
            {translation.download()}
          </LinkV2>
          {translation.follow()}
          <LinkV2
            typographyVariant="body1"
            color="mono14"
            paddingX={1}
            href={HELP_DOC_URL}
            onClick={() => buttonInteracted(context, { name: 'helpDoc', step })}
            isExternal
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14' }}
          >
            {translation.thisPage()}
          </LinkV2>
        </TextV2>
        <TextV2 typographyVariant="body2" color="mono14" marginTop="16px" marginBottom="32px">
          <b>{translation.important()}</b> {translation.importantText()}
          <LinkV2
            typographyVariant="body1"
            color="mono14"
            paddingX={1}
            href={guestsListUrl}
            onClick={() => buttonInteracted(context, { name: 'guestList', step })}
            isExternal
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14' }}
          >
            {translation.guestList()}
          </LinkV2>
          {translation.avoid()}
        </TextV2>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          <ButtonV2
            intent="neutral"
            variant="outline"
            shape="rounded"
            marginBottom="16px"
            width="350px"
            as="a"
            target="_blank"
            href={TEMPLATE_URL}
            onClick={() => buttonInteracted(context, { name: 'downloadTemplate', step })}
          >
            {translation.downloadCta()}
          </ButtonV2>
          {renderCSVReader({ startIcon: true, buttonLabel: translation.uploadFileCta() })}
        </Flex>
      </Flex>
    ),
    [buttonInteracted, context, guestsListUrl, renderCSVReader, step, translation]
  );

  const SecondStepUI = useMemo(
    () => (
      <Flex flexDirection="column">
        {hasParseError && (
          <TextV2 typographyVariant="body2" color="mono14" marginBottom="16px">
            {translation.fileFormatError()}
          </TextV2>
        )}
        {hasRequiredColumnsError && (
          <TextV2 typographyVariant="body2" color="mono14" marginBottom="16px">
            {translation.fileRequiredColumnsError()}
          </TextV2>
        )}
        {!hasParseError && !hasRequiredColumnsError && (
          <Flex alignItems="center" justifyContent="center">
            <Flex justifyContent="space-around" marginBottom="24px" width="300px">
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <TextV2 typographyVariant="hed4" color="mono14" fontWeight={600}>
                  {validRowsLength + invalidRowsLength}
                </TextV2>
                <TextV2 typographyVariant="body1" color="mono14">
                  {translation.contacts()}
                </TextV2>
              </Flex>
              <Divider orientation="vertical" />
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <TextV2 typographyVariant="hed4" color="green7" fontWeight={600}>
                  {validRowsLength}
                </TextV2>
                <TextV2 typographyVariant="body1" color="green7">
                  {translation.valid()}
                </TextV2>
              </Flex>
              <Divider orientation="vertical" />
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <TextV2 typographyVariant="hed4" color="negative6" fontWeight={600}>
                  {invalidRowsLength}
                </TextV2>
                <TextV2 typographyVariant="body1" color="negative6">
                  {translation.invalid()}
                </TextV2>
              </Flex>
            </Flex>
          </Flex>
        )}
        {!!invalidRowsLength && (
          <Box marginTop="8px" marginBottom="32px" maxHeight="200px" maxWidth="560px" overflow="scroll" border="0.5px solid" borderColor="mono5">
            <Table>
              <tbody>
                {invalidRows.map(({ firstName, lastName, email, phoneNumber, householdDisplayName, address1, address2, city, region, postalCode, country }, index) => (
                  <tr key={`${firstName},${lastName},${index}`}>
                    <td>
                      <TextV2 typographyVariant="body2" color={firstName ? 'mono14' : 'negative6'}>
                        {firstName || `Missing ${CONTACT_PROPERTIES_LABEL.firstName}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={lastName ? 'mono14' : 'negative6'}>
                        {lastName || `Missing ${CONTACT_PROPERTIES_LABEL.lastName}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={email ? 'mono14' : 'warning6'}>
                        {email || `Missing ${CONTACT_PROPERTIES_LABEL.email}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={phoneNumber ? 'mono14' : 'warning6'}>
                        {phoneNumber || `Missing ${CONTACT_PROPERTIES_LABEL.phoneNumber}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={householdDisplayName ? 'mono14' : 'warning6'}>
                        {householdDisplayName || `Missing ${CONTACT_PROPERTIES_LABEL.householdDisplayName}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={address1 ? 'mono14' : 'negative6'}>
                        {address1 || `Missing ${CONTACT_PROPERTIES_LABEL.address1}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={address2 ? 'mono14' : 'warning6'}>
                        {address2 || `Missing ${CONTACT_PROPERTIES_LABEL.address2}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={city ? 'mono14' : 'negative6'}>
                        {city || `Missing ${CONTACT_PROPERTIES_LABEL.city}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={region ? 'mono14' : 'negative6'}>
                        {region || `Missing ${CONTACT_PROPERTIES_LABEL.region}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={postalCode ? 'mono14' : 'negative6'}>
                        {postalCode || `Missing ${CONTACT_PROPERTIES_LABEL.postalCode}`}
                      </TextV2>
                    </td>
                    <td>
                      <TextV2 typographyVariant="body2" color={country ? 'mono14' : 'negative6'}>
                        {country || `Missing ${CONTACT_PROPERTIES_LABEL.country}`}
                      </TextV2>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        )}
        <TextV2 typographyVariant="body2" color="mono14">
          {translation.pleaseLong()}
          <LinkV2
            typographyVariant="body1"
            color="mono14"
            paddingX={1}
            href={TEMPLATE_URL}
            onClick={() => buttonInteracted(context, { name: 'downloadTemplate', step })}
            isExternal
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14' }}
          >
            {translation.template()}
          </LinkV2>
          {translation.follow()}
          <LinkV2
            typographyVariant="body1"
            color="mono14"
            paddingX={1}
            href={HELP_DOC_URL}
            onClick={() => buttonInteracted(context, { name: 'helpDoc', step })}
            isExternal
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14' }}
          >
            {translation.thisPage()}
          </LinkV2>
        </TextV2>
        <TextV2 typographyVariant="body2" color="mono14" marginTop="16px" marginBottom="32px">
          <b>{translation.important()}</b> {translation.importantText()}
          <LinkV2
            typographyVariant="body1"
            color="mono14"
            paddingX={1}
            href={guestsListUrl}
            onClick={() => buttonInteracted(context, { name: 'guestList', step })}
            isExternal
            _activeLink={{ color: 'mono14' }}
            _visited={{ color: 'mono14' }}
            _active={{ color: 'mono14' }}
            _pressed={{ color: 'mono14' }}
            _hover={{ color: 'mono14' }}
          >
            {translation.guestList()}
          </LinkV2>
          {translation.avoid()}
        </TextV2>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          {renderCSVReader({ buttonLabel: translation.uploadNewFileCta(), variant: 'outline', marginBottom: '16px' })}
          {!hasParseError && !hasRequiredColumnsError && (
            <ButtonV2
              startIcon={<Import />}
              intent="neutral"
              shape="rounded"
              width="350px"
              onClick={handleOnImportClick}
              loading={isCreateContactsMutationLoading}
              disabled={!validRowsLength}
            >
              {translation.importCta()}
            </ButtonV2>
          )}
        </Flex>
      </Flex>
    ),
    [
      buttonInteracted,
      context,
      guestsListUrl,
      handleOnImportClick,
      hasParseError,
      hasRequiredColumnsError,
      invalidRows,
      invalidRowsLength,
      isCreateContactsMutationLoading,
      renderCSVReader,
      step,
      translation,
      validRowsLength
    ]
  );

  const ThirdStepUI = useMemo(
    () => (
      <Flex flexDirection="column">
        {!!createContactsMutationError ? (
          <>
            <TextV2 typographyVariant="body2" color="mono14" marginY="32px">
              {translation.error()}
            </TextV2>
            <Flex alignItems="center" justifyContent="center" flexDirection="column">
              {renderCSVReader({ buttonLabel: translation.uploadNewFileCta(), marginBottom: '16px' })}
            </Flex>
          </>
        ) : (
          <>
            <TextV2 typographyVariant="body2" color="mono14" marginY="32px">
              {translation.validContacts({ quantity: validRowsLength })}
            </TextV2>
            <Flex alignItems="center" justifyContent="center" flexDirection="column">
              <ButtonV2 intent="neutral" shape="rounded" onClick={handleOnViewContactsClick} width="350px">
                {translation.viewContactsCta()}
              </ButtonV2>
            </Flex>
          </>
        )}
      </Flex>
    ),
    [createContactsMutationError, translation, renderCSVReader, validRowsLength, handleOnViewContactsClick]
  );

  const StepUI = { first: FirstStepUI, second: SecondStepUI, third: ThirdStepUI };

  const renderHeader = () => {
    if (step === 'third') {
      return !!createContactsMutationError ? (
        <TextV2 typographyVariant="hed3">{translation.errorTitle()}</TextV2>
      ) : (
        <TextV2 typographyVariant="hed3">{translation.successTitle()}</TextV2>
      );
    }
    return <TextV2 typographyVariant="hed3">{translation.title()}</TextV2>;
  };

  return <ResponsiveDialog desktopSize="xl" isOpen={isOpen} onClose={handleOnCloseDialogClick} header={renderHeader} content={() => StepUI[step]} />;
};

interface CSVUploadProps {
  context: 'print' | 'contactCollector';
  contactCollectionId: string;
  refetchQueries?: InternalRefetchQueriesInclude;
}

const CSVUpload: React.FC<CSVUploadProps> = ({ context, contactCollectionId, refetchQueries }) => {
  const { buttonInteracted } = useCSVUploadTelemetry();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('contactCollector');
  const translation = t('admin', 'csvUpload');
  const onOpen = useCallback(() => {
    setIsOpen(true);
    buttonInteracted(context, { name: 'openDialog' });
  }, [context, buttonInteracted]);
  const onClose = () => setIsOpen(false);

  const Print = useMemo(
    () => (
      <ButtonV2 startIcon={<Import />} intent="neutral" shape="rounded" variant="outline" fullWidth onClick={onOpen}>
        {translation.uploadCta()}
      </ButtonV2>
    ),
    [onOpen, translation]
  );

  const ContactCollector = useMemo(
    () => (
      <Flex padding={6} border="1px solid #FFD8BB" borderRadius={3} gap={6}>
        <Info flex="none" color="#E0946D" size={32} />
        <Flex flexDirection="column" gap={3}>
          <TextV2 color="mono12" fontWeight={600}>
            {translation.haveAList()}
          </TextV2>
          <TextV2 color="mono12">{translation.getAHead()}</TextV2>
          <Flex alignItems={{ _: 'flex-start', md: 'center' }} justifyContent={{ _: 'center', md: 'space-between' }} flexDirection={{ _: 'column', md: 'row' }} rowGap="15px">
            <Box
              alignSelf="flex-start"
              cursor="pointer"
              typographyVariant="body2"
              fontWeight={600}
              borderBottom="1px solid"
              borderColor="mono3"
              borderRadius={0}
              lineHeight={1}
              paddingY={1}
              onClick={onOpen}
              __css={{ _hover: { borderBottomColor: 'transparent' } }}
            >
              {translation.uploadYourCta()}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    ),
    [onOpen, translation]
  );

  const Trigger = {
    print: Print,
    contactCollector: ContactCollector
  };

  return (
    <>
      {isOpen && <Dialog isOpen={isOpen} onClose={onClose} context={context} contactCollectionId={contactCollectionId} refetchQueries={refetchQueries} />}
      {Trigger[context]}
    </>
  );
};

export const Root: React.FC<CSVUploadProps> = props => (
  <TelemetryProvider>
    <CSVUpload {...props} />
  </TelemetryProvider>
);
