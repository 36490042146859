/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chevron Down',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M8 12L2 5.89091L2.78505 5L8 10.3455L13.215 5L14 5.89091L8 12Z" fill="currentColor" />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2515 9.36862C18.4858 9.60294 18.4858 9.98284 18.2515 10.2172L13.1302 15.3385C12.5054 15.9633 11.4923 15.9633 10.8675 15.3385L5.748 10.219C5.51369 9.98471 5.51369 9.60481 5.748 9.37049C5.98232 9.13618 6.36222 9.13618 6.59653 9.37049L11.716 14.4899C11.8722 14.6462 12.1255 14.6462 12.2817 14.4899L17.403 9.36862C17.6373 9.13431 18.0172 9.13431 18.2515 9.36862Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
