import React from 'react';
import { Flex, TextV2, ButtonV2, Box } from '@withjoy/joykit';
import type { Property } from 'csstype';
import { GetContactCollectionByMagicLinkQuery } from '@graphql/generated';
import { StyledText } from '@apps/thanksForRsvp/components/Congratulations/components/Header/Header.styles';
import { useLandingController } from './Landing.controller';
import { getImageSource } from '@apps/contactCollector/routes/Create/components/Preview/Preview';
import { VisualType } from '@apps/contactCollector/routes/Create/components/Editor/components/Switch/Switch';
import { ErrorSplash } from '@shared/components';
import { Wrapper, Details, Footer } from '../components';
import { Context } from '../../ContactCollectorGuest.controller';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { addRendition } from '@shared/utils/photoRendition';

interface LandingProps
  extends Readonly<{
    data?: GetContactCollectionByMagicLinkQuery;
    setContext: React.Dispatch<React.SetStateAction<Context>>;
  }> {}

export const Landing: React.FC<LandingProps> = ({ data, setContext }) => {
  const {
    fontFamily,
    fontTextTransform,
    tAdmin,
    eventDisplayName,
    coupleNames,
    date,
    loading,
    error,
    handleOnProvideDetailsClick,
    handleOnVisitEventWebsiteClick,
    isMobile
  } = useLandingController(setContext, data);

  if (loading) return <JoyLogoLoader loaderKey="contact-collector-landing" />;
  if (error) return <ErrorSplash customError={{ title: tAdmin.errorPageTitle(), subtitle: tAdmin.errorPageSubTitle() }} />;

  const headerImageSource = getImageSource(data?.getContactCollectionByMagicLink?.mediaType as VisualType, data?.getContactCollectionByMagicLink?.mediaSource ?? '');

  return (
    <Wrapper>
      <img alt="source" src={addRendition({ url: headerImageSource, renditionSize: 'medium' })} width="100%" style={{ borderRadius: isMobile ? 0 : '12px' }}></img>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX="24px">
        <StyledText
          typographyVariant="hed2"
          marginTop="3rem"
          fontSize={['30px', null, '40px']}
          fontFamily={fontFamily}
          textTransform={fontTextTransform as Property.TextTransform}
          $withMargin={false}
        >
          {eventDisplayName}
        </StyledText>
        {data?.getContactCollectionByMagicLink?.eventDateDisplayEnabled && date && (
          <TextV2 typographyVariant="body2" marginTop="8px">
            {date}
          </TextV2>
        )}
        <TextV2 typographyVariant="hed6" marginTop="24px" textAlign="center">
          {data?.getContactCollectionByMagicLink?.message}
        </TextV2>
        <Box marginTop="1rem">
          <Details coupleNames={coupleNames} />
        </Box>
        <ButtonV2 marginTop="32px" onClick={handleOnProvideDetailsClick} width="100%" cursor="pointer">
          {tAdmin.provideDetails()}
        </ButtonV2>
        {data?.getContactCollectionByMagicLink?.websiteLinkEnabled && (
          <ButtonV2 cursor="pointer" onClick={handleOnVisitEventWebsiteClick} variant="link" marginTop="32px" color="mono14" fontWeight={600} width={['100%', null, '327px']}>
            {tAdmin.visitEventWebsite()}
          </ButtonV2>
        )}
        <Footer />
      </Flex>
    </Wrapper>
  );
};
