import React, { useMemo } from 'react';
import { LinkV2 } from '@withjoy/joykit';
import { InlineMessage } from '@apps/card/components/PrintJobDetails/components/InlineMessage';
import { useTranslation, useRouterHelper } from '@shared/core';
import { useFeatureFlagsContext, useFeatureValue } from '@shared/core/featureFlags';
import { StationeryTemplateIdentifiers } from '../../types';
import { ecardRoutePaths } from '@apps/ecard/Ecard.route.paths';
import { postOfficeRoutePaths } from '@apps/postOffice/PostOffice.route.paths';
import { StationeryTemplateCategoryEnum, EcardType, PostOfficeMessageType, StationeryDraftFormat } from '@graphql/generated';
import { getThemeInfoByThemeId } from '@apps/saveTheDate/SaveTheDate.utils';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface OnlyValidCountriesShippingWarningMessageProps {
  templateIdentifiers: StationeryTemplateIdentifiers | null;
  telemetryDialogId: 'restrictedInternationalShippingWarningDialog' | 'shippingAddressDialog' | 'checkoutErrorDialog';
  noMobileXMargin?: boolean;
  draftFormat: StationeryDraftFormat;
}

const STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE: Record<StationeryTemplateCategoryEnum, EcardType | PostOfficeMessageType | undefined> = {
  saveTheDate: EcardType.savethedate,
  invitation: EcardType.invitation,
  thankYou: PostOfficeMessageType.thankyou,
  holiday: undefined,
  enclosure: undefined
};

type PrintSalesAllowedCountry = {
  code: string;
  countryName: string;
  print: boolean;
  digital: boolean;
  currency: string;
};

export const isValidPrintSalesAllowedCountryListPayload = (payload: unknown): payload is PrintSalesAllowedCountry[] => {
  if (!payload) {
    return false;
  }

  if (!Array.isArray(payload)) {
    return false;
  }

  if (!payload.length) {
    return false;
  }

  if (payload.some(item => !isValidPrintSalesAllowedCountry(item))) {
    return false;
  }

  return true;
};

const isValidPrintSalesAllowedCountry = (payload: unknown): payload is PrintSalesAllowedCountry => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  if (!Object.hasOwn(payload, 'code')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'countryName')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'print')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'digital')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'currency')) {
    return false;
  }

  return true;
};

export const OnlyValidCountriesShippingWarningMessage: React.FC<OnlyValidCountriesShippingWarningMessageProps> = props => {
  const { templateIdentifiers, telemetryDialogId, noMobileXMargin = false, draftFormat } = props;
  const { buildPath } = useRouterHelper();
  const { t } = useTranslation('stationery');
  const translations = t('dashboard', 'restrictedInternationalShippingWarningDialog', draftFormat);
  const { formatToUrl } = useFeatureFlagsContext();
  const { value: printSalesAllowedCountryListValue, payload: printSalesAllowedCountryListPayload } = useFeatureValue('printSalesAllowedCountryList');
  const { restrictedInternationalShippingWarningDialogCreateEcardClicked } = useCardTelemetry();

  const isPrintSalesAllowedCountryListPayloadEnabledAndValid = printSalesAllowedCountryListValue && isValidPrintSalesAllowedCountryListPayload(printSalesAllowedCountryListPayload);

  const digitalCountryList = useMemo(
    () =>
      isPrintSalesAllowedCountryListPayloadEnabledAndValid
        ? printSalesAllowedCountryListPayload
            .filter(item => item.digital)
            .map(item => item.countryName)
            .toString()
            .replaceAll(',', ', ')
        : 'United States',
    [printSalesAllowedCountryListPayload, isPrintSalesAllowedCountryListPayloadEnabledAndValid]
  );
  const digitalCountryListLength = digitalCountryList.match(new RegExp(',', 'g'))?.length ?? 0;
  const digitalCountryListString = !digitalCountryListLength
    ? translations.supportedCountriesSingular({ countries: digitalCountryList })
    : translations.supportedCountriesPlural({ countries: digitalCountryList });

  const printCountryList = useMemo(
    () =>
      isPrintSalesAllowedCountryListPayloadEnabledAndValid
        ? printSalesAllowedCountryListPayload
            .filter(item => item.print)
            .map(item => item.countryName)
            .toString()
            .replaceAll(',', ', ')
        : 'United States',
    [printSalesAllowedCountryListPayload, isPrintSalesAllowedCountryListPayloadEnabledAndValid]
  );
  const printCountryListLength = printCountryList.match(new RegExp(',', 'g'))?.length ?? 0;
  const printCountryListString = !printCountryListLength
    ? translations.supportedCountriesSingular({ countries: printCountryList })
    : translations.supportedCountriesPlural({ countries: printCountryList });

  const buildLinkURL = () => {
    let url: URL;

    if (templateIdentifiers?.category && templateIdentifiers.themeId) {
      const type = STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE[templateIdentifiers.category];
      if (type) {
        if (templateIdentifiers.category === StationeryTemplateCategoryEnum.thankYou) {
          url = new URL(window.location.origin + buildPath(`email/${postOfficeRoutePaths.create.path}`) + `?draftType=${type}`);
        } else {
          url = new URL(
            window.location.origin +
              buildPath(
                ecardRoutePaths.design.goToPath(
                  `${templateIdentifiers.category === StationeryTemplateCategoryEnum.saveTheDate && !!getThemeInfoByThemeId(templateIdentifiers.themeId) ? '' : 'ecard'}/${type}`
                )
              )
          );
          url.searchParams.set('theme', templateIdentifiers.themeId);
          url.searchParams.set('save', 'true');
        }
      } else {
        url = new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath(`ecard/${STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE.saveTheDate}`)));
      }
    } else {
      url = new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath(`ecard/${STATIONERY_TEMPLATE_TO_ECARD_TYPE_OR_EMAIL_TYPE.saveTheDate}`)));
    }

    return formatToUrl(url.toString());
  };

  const handleOnClick = () => {
    templateIdentifiers?.themeId && restrictedInternationalShippingWarningDialogCreateEcardClicked({ themeId: templateIdentifiers.themeId, dialogId: telemetryDialogId });
  };

  const isThankYou = templateIdentifiers?.category === StationeryTemplateCategoryEnum.thankYou;

  const shouldShowWarningWithDigitalSupport =
    templateIdentifiers?.category === StationeryTemplateCategoryEnum.saveTheDate || templateIdentifiers?.category === StationeryTemplateCategoryEnum.invitation;

  return (
    <InlineMessage
      icon="warning"
      message={
        <>
          {draftFormat === StationeryDraftFormat.print && !!printCountryList && printCountryListString}{' '}
          {draftFormat === StationeryDraftFormat.digital && !!digitalCountryList && digitalCountryListString}{' '}
          {shouldShowWarningWithDigitalSupport ? (
            <>
              {isThankYou ? translations.warningMessageEmailText() : translations.warningMessageEcardText()}{' '}
              <LinkV2
                _activeLink={{ color: 'mono14' }}
                _visited={{ color: 'mono14' }}
                _active={{ color: 'mono14' }}
                _pressed={{ color: 'mono14' }}
                _hover={{ color: 'mono14', textDecoration: 'underline #D6D6D6' }}
                textDecoration="underline #333333"
                color="mono14"
                fontSize="15px"
                href={buildLinkURL()}
                isExternal
                onClick={handleOnClick}
              >
                {isThankYou ? translations.warningMessageLinkThankYou() : translations.warningMessageLink()}
              </LinkV2>
            </>
          ) : (
            translations.warningMessageNonStdOrInvitationText()
          )}
        </>
      }
      noMobileXMargin={noMobileXMargin}
    />
  );
};
