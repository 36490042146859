import { COUNTRY_LIST } from '@apps/card/routes/CardCustomizer/components/AddressDialog/AddressDialog.controller';

// TODO: Consume from FF
const COUNTRY_ALIASES: { [key: string]: string } = {
  'United States of America': 'United States',
  'US of A': 'United States',
  USA: 'United States',
  'U.S.A.': 'United States',
  US: 'United States',
  'U.S.': 'United States',
  Ca: 'Canada',
  Can: 'Canada',
  AU: 'Australia',
  Aus: 'Australia',
  NZ: 'New Zealand',
  UK: 'United Kingdom',
  'U.K.': 'United Kingdom',
  England: 'United Kingdom',
  Scotland: 'United Kingdom',
  Wales: 'United Kingdom',
  'Northern Ireland': 'United Kingdom',
  'Great Britain': 'United Kingdom',
  GB: 'United Kingdom',
  'G.B.': 'United Kingdom'
};
// TODO: Consume from FF
export const CONTACT_PROPERTIES_LABEL: { [key: string]: string } = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email (Optional)',
  phoneNumber: 'Phone Number (Optional)',
  householdDisplayName: 'Name on Envelope (Optional)',
  address1: 'Address Line 1',
  address2: 'Address Line 2 (Optional)',
  city: 'City',
  region: 'State/Region',
  postalCode: 'Postal Code',
  country: 'Country'
};

const getCountryNameFromAliases = (name: string): string => Object.entries(COUNTRY_ALIASES).find(value => value[0] === name)?.[1] ?? name;

// this is necessary due to a library limitation, when we have repeated values in a row a dash plus a number is added at the bottom
const replaceDashEmptyStrings = (value?: string): string => value?.replace(/(_)\d/g, '') ?? '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasAllRequiredColumns = (csvContacts: any[]) =>
  csvContacts.every(
    csvContact =>
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.firstName) &&
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.lastName) &&
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.address1) &&
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.city) &&
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.region) &&
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.postalCode) &&
      Object.hasOwn(csvContact, CONTACT_PROPERTIES_LABEL.country)
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapCSVContactToContact = (csvContact: any): Contact => ({
  firstName: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.firstName]),
  lastName: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.lastName]),
  email: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.email]),
  phoneNumber: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.phoneNumber]),
  householdDisplayName: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.householdDisplayName]),
  address1: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.address1]),
  address2: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.address2]),
  city: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.city]),
  region: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.region]),
  postalCode: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.postalCode]),
  country: replaceDashEmptyStrings(csvContact[CONTACT_PROPERTIES_LABEL.country])
});

export const isAValidContact = (value: Contact): value is Contact =>
  !!(value.firstName && value.lastName && value.address1 && value.city && value.region && value.postalCode && value.country);

const getCountryCodeByCountryName = (name: string): string => COUNTRY_LIST.find(({ label }) => label === name)?.value ?? '';

export const mapContactsToMutationData = (contacts: Contact[], contactCollectionId: string, eventFirebaseId: string) => {
  return contacts.map(contact => ({
    address: {
      address1: contact.address1,
      address2: contact.address2,
      city: contact.city,
      country: getCountryNameFromAliases(contact.country),
      countryCode: getCountryCodeByCountryName(getCountryNameFromAliases(contact.country)),
      postalCode: contact.postalCode,
      region: contact.region,
      validated: false
    },
    contact: {
      contactCollectionId,
      firebaseEventId: eventFirebaseId,
      email: contact.email ?? '',
      firstName: contact.firstName,
      lastName: contact.lastName,
      householdDisplayName: contact.householdDisplayName
    },
    phoneNumbers: contact.phoneNumber
      ? [
          {
            allowSMS: true,
            mobile: true,
            number: contact.phoneNumber
          }
        ]
      : undefined
  }));
};
