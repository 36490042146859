import { useMemo } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventType } from '@shared/utils/eventType';
import { EventType } from '@graphql/generated';
import { useAdminRegistryState } from '../../state/context/AdminRegistry';
import { OVERVIEW_LAYOUT_CONFIG } from '.';

export const useMergeAddGiftsAndOverviewFeature = () => {
  const { eventType } = useEventType();
  const skip = eventType !== EventType.wedding;
  return {
    isEnabled: useFeatureValue('registryMergeAddGiftAndOverviewPagesEnabled', { skip }).value === 'treatment'
  };
};

export const useAddGiftsAndOverviewController = () => {
  const { registryItemsCount: giftCount, loadingRegistryRegistries } = useAdminRegistryState();

  const layoutVariant = useMemo(() => {
    if (typeof giftCount !== 'number') {
      return null;
    }
    const variant = OVERVIEW_LAYOUT_CONFIG.variants.find(({ condition }) => {
      if (condition.minGiftCount && condition.minGiftCount > giftCount) {
        return false;
      }
      return true;
    });
    return variant ?? OVERVIEW_LAYOUT_CONFIG.default;
  }, [giftCount]);

  return {
    layoutVariant,
    giftCount,
    loadingRegistryRegistries
  };
};
