import { useDisclosure } from '@withjoy/joykit';
import { useEffect, useMemo } from 'react';
import { getLocalStorage } from '@shared/core';
import { useNativeState } from '@shared/core/native';

const KEY = 'contactCollectorPrintCrossDialogSeen';
const TIMESTAMP_KEY = 'contactCollectorPrintCrossDialogSeenAt';

const localStorage = getLocalStorage();

const getLocalStorageKey = (key: string, eventId?: string) => (eventId ? `${eventId}-${key}` : '');

interface UsePrintCrossSellModalProps {
  eventId: string;
}

const usePrintCrossSellModal = ({ eventId }: UsePrintCrossSellModalProps) => {
  const { isNative } = useNativeState();
  const memoisedValues = useMemo(() => localStorage.getItem(getLocalStorageKey(KEY, eventId)), [eventId]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const canBeOpened = memoisedValues !== 'true' && !isNative;

  useEffect(() => {
    if (canBeOpened) {
      onOpen();
      localStorage.setItem(getLocalStorageKey(KEY, eventId), 'true');
      localStorage.setItem(getLocalStorageKey(TIMESTAMP_KEY, eventId), new Date().toISOString());
    }
  }, [canBeOpened, onOpen, eventId]);

  const handleClose = () => {
    onClose();
  };

  return { isOpen, handleClose };
};

export default usePrintCrossSellModal;
