import { styled } from '@withjoy/joykit';

export const Table = styled.table`
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  border-spacing: 0;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem 2rem 0.5rem 0.5rem;

    :last-child {
      border-right: 0;
    }
  }
`;
