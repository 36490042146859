import { useState, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OptionType } from '@withjoy/joykit';
import { AddressSearchType, useAddressAutocomplete, AddressResponseType } from '@apps/registry/common/utils/smartyClient';
import { AddressDialogPayload } from './AddressDialog';
import { useTranslation } from '@shared/core';
import { useCardCustomizerTelemetry } from '../../CardCustomizer.telemetry';
import { AddressType } from './AddressDialog.types';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFeatureValue } from '@shared/core/featureFlags';
import { e164Regex } from '@apps/contactCollector/guest/routes/Form/Form.controller';
import { CountryData } from 'react-intl-tel-input';
import { isValidPrintSalesAllowedCountryListPayload } from '@apps/card/routes/Dashboard/components/RestrictedInternationalShippingWarningProvider/components/OnlyValidCountriesShippingWarningMessage';
import { StationeryDraftFormat } from '@graphql/generated';

// exported from https://usastatescode.com/state-array-json
export const US_STATES = [
  { label: '', value: '' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export const COUNTRY_LIST = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia, Plurinational State of', value: 'BO' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, the Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea, Democratic People's Republic of", value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, the Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela, Bolivarian Republic of', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
  { label: 'Åland Islands', value: 'AX' }
];

// exclude Alaska/Hawaii per definition
const contiguousUSOnly = (value: string) => /^(?!AK|HI)/.test(value);

export interface SuggestedAddress extends Readonly<{ address: string; city: string; state: string; zipCode: string }> {}

export type AddressOption = 'original' | 'suggested';

type AddressDialogStep = 'form' | 'suggestion';

interface UseAddressDialogControllerArgs {
  addressType: AddressType;
  initialValues: AddressDialogPayload;
  onClose: () => void;
  onSave: (address: AddressDialogPayload) => void;
  setPhoneNumber?: React.Dispatch<React.SetStateAction<string>>;
  draftFormat: StationeryDraftFormat;
}

export const useAddressDialogController = ({ addressType, initialValues, onClose, onSave, setPhoneNumber, draftFormat }: UseAddressDialogControllerArgs) => {
  const { t } = useTranslation('stationery');
  const tAddressDialog = t('cardCustomizer', 'addressDialog');
  const tFormValidation = t('cardCustomizer', 'addressDialog', 'formValidation');
  const tAddressSugestion = t('cardCustomizer', 'addressDialog', 'addressSugestion');
  const { Lookup, handleAddressSearch } = useAddressAutocomplete();
  const [isValidatingAddress, setIsValidatingAddress] = useState<boolean>(false);
  const [addressOption, setAddressOption] = useState<AddressOption>('original');
  const [suggestedAddress, setSuggestedAddress] = useState<SuggestedAddress | undefined>(undefined);
  const [step, setStep] = useState<AddressDialogStep>('form');
  const { customizationButtonInteracted, unableToFetchSmartySuggestions, fetchedSmartySuggestions } = useCardCustomizerTelemetry();
  const { value: printAllowAlaskaHawaiiShipping } = useFeatureValue('printAllowAlaskaHawaiiShipping');
  const { payload: contactCollectionDisablePhoneNumberValidationForFlagPayload = [] } = useFeatureValue('contactCollectionDisablePhoneNumberValidationFor');
  const [internalPhoneNumber, setInternalPhoneNumber] = useState<string>('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false);
  const [phoneNumberFocused, setIsPhoneNumberFocused] = useState<boolean>(false);
  const { value: printSalesAllowedCountryListValue, payload: printSalesAllowedCountryListPayload } = useFeatureValue('printSalesAllowedCountryList');

  const isPrintSalesAllowedCountryListPayloadEnabledAndValid = printSalesAllowedCountryListValue && isValidPrintSalesAllowedCountryListPayload(printSalesAllowedCountryListPayload);

  const digitalCountryList = isPrintSalesAllowedCountryListPayloadEnabledAndValid
    ? printSalesAllowedCountryListPayload.filter(item => item.digital).map(item => item.code)
    : ['US'];
  const digitalCountryListAllowed = COUNTRY_LIST.filter(({ value }) => digitalCountryList.includes(value));

  const printCountryList = isPrintSalesAllowedCountryListPayloadEnabledAndValid ? printSalesAllowedCountryListPayload.filter(item => item.print).map(item => item.code) : ['US'];
  const printCountryListAllowed = COUNTRY_LIST.filter(({ value }) => printCountryList.includes(value));

  const countryListAllowed = draftFormat === StationeryDraftFormat.digital ? digitalCountryListAllowed : printCountryListAllowed;

  const prefixTelemetryPayloadProperty = useEventCallback((property: string) => `${addressType}Dialog_${step}Step_${property}`);

  let stateValidation = Yup.string().trim().required(tFormValidation.required()).test('Only contiguous US', tFormValidation.contiguousUS(), contiguousUSOnly);
  // IF we are using card service checkout we can support checkout in alaska and hawaii now :)
  if (printAllowAlaskaHawaiiShipping === 'on') {
    stateValidation = Yup.string().trim().required(tFormValidation.required());
  }

  const formik = useFormik({
    initialValues: { ...initialValues, state: initialValues.state || US_STATES[0].value },
    onSubmit: onSave,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object<AddressDialogPayload>({
      name: Yup.string().trim().required(tFormValidation.required()),
      address1: Yup.string().trim().required(tFormValidation.required()),
      address2: Yup.string().trim(),
      postalCode: Yup.string()
        .trim()
        .when('countryCode', {
          is: 'US',
          then: Yup.string()
            .trim()
            .required(tFormValidation.required())
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, tFormValidation.invalidZipCode()),
          otherwise: Yup.string().trim().required(tFormValidation.required())
        }),
      city: Yup.string().trim().required(tFormValidation.required()),
      state: Yup.string()
        .trim()
        .when('countryCode', {
          is: 'US',
          then: stateValidation,
          otherwise: Yup.string()
            .trim()
            .when('countryCode', {
              is: 'GB',
              then: Yup.string().trim(),
              otherwise: Yup.string().trim().required(tFormValidation.required())
            })
        }),
      country: Yup.string().trim().required(tFormValidation.required()),
      countryCode: Yup.string().trim().required(tFormValidation.required())
    })
  });

  const isNationalAddress = useMemo(() => formik.values.countryCode === 'US', [formik.values.countryCode]);

  //TODO: this could be a list of countries
  const showStateBasedOnCountry = useMemo(() => formik.values.countryCode !== 'GB', [formik.values.countryCode]);

  const handleClose = () => {
    formik.resetForm();
    setStep('form');
    onClose();
  };

  const handleOnStateDropdownChange = (option: OptionType | null): void => {
    if (!option) {
      return;
    }
    formik.setFieldValue('state', option.value);
  };

  const handleOnCountryDropdownChange = (option: OptionType | null): void => {
    if (!option) return;
    formik.setFieldValue('country', option.label);
    formik.setFieldValue('countryCode', option.value);
  };

  const getFormFieldError = (fieldName: keyof AddressDialogPayload) => {
    const { errors, touched } = formik;
    return touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined;
  };

  const handleNationalAddressAutocomplete = useCallback(
    async (value: string) => {
      const lookup: AddressSearchType = new Lookup(value);

      lookup.includeOnlyZIPCodes = [formik.values.postalCode];

      const response = await handleAddressSearch(lookup);
      return response;
    },
    [Lookup, formik.values.postalCode, handleAddressSearch]
  );

  const fetchSuggestions = useCallback(
    async (text: string): Promise<boolean> => {
      let areAddressesEqual = false;
      setIsValidatingAddress(true);
      try {
        const suggestions = await handleNationalAddressAutocomplete(text);
        fetchedSmartySuggestions(addressType, suggestions.length || 0);

        if (suggestions?.length) {
          setAddressOption('suggested');

          const suggestion = suggestions[0] as AddressResponseType;
          setSuggestedAddress({
            address: suggestion.streetLine,
            city: suggestion.city,
            state: suggestion.state,
            zipCode: suggestion.zipcode
          });

          areAddressesEqual =
            suggestion.streetLine === formik.values.address1.trim() &&
            suggestion.city === formik.values.city.trim() &&
            suggestion.state === formik.values.state.trim() &&
            suggestion.zipcode === formik.values.postalCode.trim();
        } else {
          setAddressOption('original');
          setSuggestedAddress(undefined);
        }
      } catch (error) {
        unableToFetchSmartySuggestions(error);
        setAddressOption('original');
        setSuggestedAddress(undefined);
      }
      setIsValidatingAddress(false);
      return areAddressesEqual;
    },
    [
      fetchedSmartySuggestions,
      addressType,
      formik.values.address1,
      formik.values.city,
      formik.values.postalCode,
      formik.values.state,
      handleNationalAddressAutocomplete,
      unableToFetchSmartySuggestions
    ]
  );

  const handleOnCancel = () => {
    if (step === 'suggestion') {
      customizationButtonInteracted({ property: prefixTelemetryPayloadProperty(`editAddressCta`) });
      // Go back to form step (which is the first step)
      setStep('form');
    } else {
      customizationButtonInteracted({ property: prefixTelemetryPayloadProperty('cancelCta') });
      handleClose();
    }
  };

  const handleOnConfirmAddress = async () => {
    if (isNationalAddress && addressOption === 'suggested' && suggestedAddress) {
      await formik.setValues({
        ...formik.values,
        address1: suggestedAddress.address,
        city: suggestedAddress.city,
        state: suggestedAddress.state,
        postalCode: suggestedAddress.zipCode
      });
    }
    if (formik.isValid) {
      await formik.submitForm();
      onClose();
      setStep('form');
    }
  };

  const handleOnSave = async () => {
    if (isNationalAddress && step === 'form') {
      const areAddressesEqual = await fetchSuggestions(formik.values.address1);
      // if address manually entered is equal than the suggested by smarty, skip address suggestion step
      if (areAddressesEqual) {
        customizationButtonInteracted({ property: prefixTelemetryPayloadProperty('saveAddressCta'), value: 'original' });
        return handleOnConfirmAddress();
      }
      customizationButtonInteracted({ property: prefixTelemetryPayloadProperty('showSuggestionCta') });
      setStep('suggestion');
    } else {
      customizationButtonInteracted({ property: prefixTelemetryPayloadProperty('saveAddressCta'), value: addressOption });
      handleOnConfirmAddress();
    }
  };

  const handleSelectSuggestion = useEventCallback((selection: AddressOption) => {
    setAddressOption(selection);
    customizationButtonInteracted({ property: prefixTelemetryPayloadProperty('addressOption'), value: selection });
  });

  const handleOnPhoneNumberChange = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string): void => {
    if (!e164Regex.test(value)) {
      return;
    }
    const isPhoneNumberValidOrSkipped = isValid || (contactCollectionDisablePhoneNumberValidationForFlagPayload as string[])?.includes(selectedCountryData?.iso2 ?? '');
    setIsPhoneNumberValid(isPhoneNumberValidOrSkipped);
    setInternalPhoneNumber(value);
    if (isPhoneNumberValidOrSkipped) {
      // remove empty spaces and - to convert to e164 format
      setPhoneNumber?.(fullNumber.replace(/ |-/g, '').trim());
    }
  };

  const handleOnPhoneNumberFocus = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string): void => {
    setIsPhoneNumberFocused(true);
  };

  return {
    isValidatingAddress,
    addressOption,
    setAddressOption: handleSelectSuggestion,
    suggestedAddress,
    US_STATES,
    formik,
    step,
    getFormFieldError,
    handleClose,
    handleOnStateDropdownChange,
    handleOnCancel,
    handleOnSave,
    tAddressDialog,
    tAddressSugestion,
    internalPhoneNumber,
    handleOnPhoneNumberChange,
    handleOnPhoneNumberFocus,
    isPhoneNumberValid,
    phoneNumberFocused,
    countryListAllowed,
    isNationalAddress,
    handleOnCountryDropdownChange,
    showStateBasedOnCountry
  };
};
