import React from 'react';
import { RoutesProvider, RoutesPaths } from '@shared/core';
import globalWindow from '@shared/core/globals';

/**
 * Example Route name types. Defines the routes that are available
 */
type RoutesNames =
  | 'home'
  | 'dashboard'
  | 'design'
  | 'checklist'
  | 'vendorShowcase'
  | 'guests'
  | 'playground'
  | 'invitations'
  | 'photos'
  | 'rsvp'
  | 'registry'
  | 'registryManage'
  | 'inspiration'
  | 'settings'
  | 'account'
  | 'settingsSecurity'
  | 'settingsGeneral'
  | 'settingsMenu'
  | 'video'
  | 'domain'
  | 'ideas'
  | 'postOffice'
  | 'ecard'
  | 'card'
  | 'story'
  | 'schedule'
  | 'travel'
  | 'accommodations'
  | 'website'
  | 'customPage'
  | 'registryLinks'
  | 'vip'
  | 'faq'
  | 'shop'
  | 'savethedate'
  | 'eventServiceCenter'
  | 'planning'
  | 'hotelBlock'
  | 'contact'
  | 'hotelReminder'
  | 'event'
  | 'planningAccommodations'
  | 'webisteSettings'
  | 'websiteOverview'
  | 'photoCollection';

/**
 * Example route paths. Has a path for the <Router /> and a function for history to push to.
 */
export const routePaths: RoutesPaths<RoutesNames> = {
  home: {
    path: '',
    goToPath: () => ''
  },
  dashboard: {
    path: 'dashboard',
    goToPath: () => 'dashboard'
  },
  design: {
    path: 'design',
    goToPath: () => 'design'
  },
  checklist: {
    path: 'checklist',
    goToPath: () => 'checklist'
  },
  vendorShowcase: {
    path: 'showcase',
    goToPath: () => 'showcase'
  },
  guests: {
    path: 'guests',
    goToPath: () => 'guests'
  },
  playground: {
    path: 'playground',
    goToPath: () => 'playground'
  },
  invitations: {
    path: 'invitations',
    goToPath: () => 'invitations'
  },
  photos: {
    path: 'photos',
    goToPath: () => 'photos'
  },
  rsvp: {
    path: 'rsvp',
    goToPath: () => 'rsvp'
  },
  registry: {
    path: 'registry',
    goToPath: () => 'registry'
  },
  registryManage: {
    path: 'registry/manage',
    goToPath: () => 'registry/manage'
  },
  inspiration: {
    path: 'inspiration',
    goToPath: () => 'inspiration'
  },
  settings: {
    path: 'settings',
    goToPath: () => 'settings'
  },
  account: {
    path: 'settings/account',
    goToPath: () => 'settings/account'
  },
  settingsSecurity: {
    path: 'settings/security',
    goToPath: () => 'settings/security'
  },
  settingsGeneral: {
    path: 'settings/general',
    goToPath: () => 'settings/general'
  },
  webisteSettings: {
    path: 'settings/website',
    goToPath: () => 'settings/website'
  },
  settingsMenu: {
    path: 'settings/menu',
    goToPath: () => 'settings/menu'
  },
  video: {
    path: 'video',
    goToPath: () => 'video'
  },
  domain: {
    path: 'domain',
    goToPath: () => 'domain'
  },
  ideas: {
    path: 'ideas',
    goToPath: () => 'ideas'
  },
  postOffice: {
    path: 'email',
    goToPath: () => 'email'
  },
  story: {
    path: 'story',
    goToPath: () => 'story'
  },
  schedule: {
    path: 'schedule',
    goToPath: () => 'schedule'
  },
  travel: {
    path: 'travel',
    goToPath: () => 'travel'
  },
  accommodations: {
    path: 'accommodations',
    goToPath: () => 'accommodations'
  },
  website: {
    path: 'website/:pageSlug',
    goToPath: (pageSlug: string) => `website/${pageSlug}`
  },
  customPage: {
    path: 'website/page/:pageSlug',
    goToPath: (pageSlug: string) => `website/page/${pageSlug}`
  },
  registryLinks: {
    path: 'registrylinks',
    goToPath: () => 'registrylinks'
  },
  vip: {
    path: 'vip',
    goToPath: () => 'vip'
  },
  faq: {
    path: 'faq',
    goToPath: () => 'faq'
  },
  shop: {
    path: 'registry/shop',
    goToPath: () => 'registry/shop'
  },
  ecard: {
    path: 'ecard/:draftType',
    goToPath: (draftType: string) => `ecard/${draftType}`
  },
  card: {
    path: 'cards',
    goToPath: (draftType: string) => `cards`
  },
  savethedate: {
    path: 'savethedate',
    goToPath: () => 'savethedate'
  },
  eventServiceCenter: {
    path: 'newservicecenter',
    goToPath: () => 'newservicecenter'
  },
  planning: {
    path: 'planning',
    goToPath: () => 'planning'
  },
  hotelBlock: {
    path: 'planning/hotelblock',
    goToPath: () => 'planning/hotelblock'
  },
  planningAccommodations: {
    path: 'planning/accommodations',
    goToPath: () => 'planning/accommodations'
  },
  contact: {
    path: 'contact',
    goToPath: () => 'contact'
  },
  hotelReminder: {
    path: 'hotel-reminder',
    goToPath: () => 'hotel-reminder'
  },
  event: {
    path: 'event',
    goToPath: () => 'event'
  },
  websiteOverview: {
    path: 'website/overview',
    goToPath: () => 'website/overview'
  },
  photoCollection: {
    path: 'website/photos',
    goToPath: () => 'website/photos'
  }
};

export const Router: React.FC<{ eventHandle: string }> = ({ eventHandle, children }) => {
  return <RoutesProvider handle={eventHandle}>{children}</RoutesProvider>;
};

export const monorepoRoutePaths = {
  accountPersonalInfo: 'account/personal-info',
  accountVerifyEmail: `/account/verify-email?prev=${globalWindow.location?.href}?dialog=verifyEmail`,
  eCardChangeTheDate: 'ecard/changethedate',
  emailCreate: 'email/create',
  eCardCustom: 'ecard/custom',
  eCardInvitations: 'ecard/invitation',
  eCardSaveTheDate: 'ecard/savethedate',
  faq: 'faq',
  guests: 'guests',
  welcome: 'welcome',
  photos: 'photos',
  registryShop: 'registry/shop',
  registryOverview: 'registry',
  registryManage: 'registry/manage',
  registryTrack: 'registry/track',
  rsvp: 'rsvp',
  rsvpSettings: 'rsvp/settings',
  schedule: 'schedule',
  settings: 'settings',
  settingsAccount: 'settings/account',
  settingsGeneral: 'settings/general',
  settingsMenu: 'settings/menu',
  settingsSecurity: 'settings/security',
  story: 'story',
  travel: 'travel',
  video: 'video',
  vip: 'vip',
  writersblock: 'writersblock'
};
